import { z } from 'zod';

/** Location schema. */
export const locationSchema = z.object({

	/** Id. */
	id: z.number(),

	/** Name. */
	name: z.string(),

	/** Location unique identifier string. */
	locationId: z.string(),

	/** Can quote. */
	canQuote: z.boolean(),
});

/** Location. */
export type Location = Readonly<z.infer<typeof locationSchema>>;
